.Box {
    background-color: #819fdb;
    opacity: 0.5;
}

.StartButton {
    background-color: brown;
}

.StartInfoText {
    font-size: 200%;
    margin: 0;
    height: 100%;
}

.StartButtonText {
    font-size: 250%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ReplayBox {
    background-color: white;
}

.ReplayText {
    background-color: #e43d20e3;
    font-size: 200%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.PieceImage {
    position: relative;
    width: 150;
    height: 150;
}

.swapImage {
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 1;
}


.swapImage:hover {
    cursor: pointer;
    /* Changes the cursor to a pointer when hovering */
    border-color: red;
    /* Example: changes border color to red when hovering */
    /* Add any additional hover styles you want here */
}



.UserPanel {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    position: absolute;
    background-color: #FFFFF0;
    /*background-color: #876851;*/
    border-radius: 20px;
    align-items: center;
    top: 0;
    left: 710px;
    width: 220px;
    height: 700px;
}

.GameModeOptionButton {
    /*background-color: #D6A37A;*/
    background-color: #F6E2C0;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    /* top: 0;
    left: 720px; */
    width: 150px;
    height: 80px;
}

.LostPieces {
    display: flex;
    flex-direction: row;
}

.TestPanel {
    position: absolute;
    top: 10vh;
    left: 100vh;
    width: 200px;
    height: 600px;
    background-color: gray;
    justify-content: center;
}