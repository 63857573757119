.App {
  text-align: center;
  background-color: #66686C;
  min-height: 100vh;
  min-width:100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}


.body{
	background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: black;
}


.game_board_container{
  position: absolute;
  background-color: 'red';
  top: 0;
  left: 0;
}

.game_container{
  position: absolute;
  top: 10vh;
  left: 18vh;
  content: " "; 
  display: block;
  clear: both;
}

.Banner-PlaceHolder{
  position: absolute;
  top: 15vh;
  left: 2.5vh;
  width: 120px;
  height: 600px;
  /* background-color: greenyellow; */
  border: 2px solid black; 
  border-color: black;
}

#all{
	width: 1088px;
	margin: 0px auto;
	padding: 10px 5px 0px;
	font-family: sans-serif;
	color: #423220;
	line-height: 170%;
}

#left{
	float: left;
	width: 728px;
	margin: 26px 50px 0px 0px;
}


#empty_board{
  width:99px;
  height:79px;
}

#canvas{
  background: #61dafb;
  border-radius: 5px;
}
